import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import ButtonWithText from '../../components/ButtonWithText';
import { Typography, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '2rem',
  },
  inputContainer: {
    display: 'flex',
    width: '100%',
    marginBottom: '1rem',
  },
  input: {
    flexGrow: 1,
    marginRight: '1rem',
  },
  listContainer: {
    width: '100%',
    height: '100%',
    marginTop: '1rem',
    
  },
  list: {
    marginBottom: '5px',
    backgroundColor: theme.palette.inputBackgroundColor,
    borderRadius: '16px',
  },
}));

const ToDoList = () => {
  const classes = useStyles();
  const [task, setTask] = useState('');
  const [tasks, setTasks] = useState([]);
  const [editIndex, setEditIndex] = useState(-1);
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    const savedTasks = localStorage.getItem('tasks');
    if (savedTasks) {
      setTasks(JSON.parse(savedTasks));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('tasks', JSON.stringify(tasks));
  }, [tasks]);

  const handleTaskChange = (event) => {
    setTask(event.target.value);
  };

  const handleAddTask = () => {
    if (!task.trim()) {
      return;
    }

    const now = new Date();
    if (editIndex >= 0) {
      const newTasks = [...tasks];
      newTasks[editIndex] = { text: task, updatedAt: now, createdAt: newTasks[editIndex].createdAt };
      setTasks(newTasks);
      setTask('');
      setEditIndex(-1);
      handleCloseModal();
    } else {
      setTasks([...tasks, { text: task, createdAt: now, updatedAt: now }]);
      setTask('');
    }
  };

  const handleEditTask = (index) => {
    setTask(tasks[index].text);
    setEditIndex(index);
    setOpenModal(true);
  };

  const handleDeleteTask = (index) => {
    const newTasks = [...tasks];
    newTasks.splice(index, 1);
    setTasks(newTasks);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setTask('');
    setEditIndex(-1);
  };

  return (
    <div className={classes.root}>
      <div className={classes.inputContainer}>
        <TextField
          className={classes.input}
          label="Nova tarefa"
          value={task}
          onChange={handleTaskChange}
          variant="outlined"
        />
        <ButtonWithText variant="contained" color="primary" onClick={handleAddTask}>
          {editIndex >= 0 ? 'Salvar' : 'Adicionar'}
        </ButtonWithText>
      </div>
      {tasks.length > 0 ? (
        <div className={classes.listContainer}>
          <List>
            {tasks.map((task, index) => (
              <ListItem key={index} className={classes.list}>
                <ListItemText primary={task.text} secondary={task.updatedAt.toLocaleString()} />
                <ListItemSecondaryAction>
                  <IconButton onClick={() => handleEditTask(index)}>
                    <EditIcon />
                  </IconButton>
                  <IconButton onClick={() => handleDeleteTask(index)}>
                    <DeleteIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        </div>
      ) : (
        <Typography variant="h6" align="center" style={{ fontSize: '16px', color: 'grey', marginTop: '16px' }}>
          Nenhuma tarefa encontrada
        </Typography>
      )}

      <Dialog open={openModal} onClose={handleCloseModal}>
        <DialogTitle>Editar Tarefa</DialogTitle>
        <DialogContent>
          <DialogContentText>Edite a sua tarefa abaixo:</DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label="Tarefa"
            type="text"
            fullWidth
            value={task}
            onChange={handleTaskChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} variant="outlined">
            Cancelar
          </Button>
          <ButtonWithText onClick={handleAddTask} variant="contained" color="primary">
            Salvar
          </ButtonWithText>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ToDoList;
