import React, { useEffect, useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Drawer from "@material-ui/core/Drawer";
import Link from "@material-ui/core/Link";
import InputLabel from "@material-ui/core/InputLabel";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import CreateIcon from "@material-ui/icons/Create";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

import { i18n } from "../../translate/i18n";

import ContactDrawerSkeleton from "../ContactDrawerSkeleton";
import MarkdownWrapper from "../MarkdownWrapper";
import { CardHeader } from "@material-ui/core";
import { ContactForm } from "../ContactForm";
import ContactModal from "../ContactModal";
import { ContactNotes } from "../ContactNotes";

const drawerWidth = 320;

const useStyles = makeStyles(theme => ({
	drawer: {
		width: drawerWidth,
		flexShrink: 0,
	},
	drawerPaper: {
		width: drawerWidth,
		display: "flex",
		// borderTop: "1px solid rgba(0, 0, 0, 0.12)",
		// borderRight: "1px solid rgba(0, 0, 0, 0.12)",
		// borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
		// borderTopRightRadius: 4,
		// borderBottomRightRadius: 4,
		backgroundColor: theme.palette.cardnormal,
	},
	header: {
		display: "flex",
		borderBottom: `1px solid ${theme.palette.divider}`,
		// backgroundColor: theme.palette.cardnormal, //DARK MODE PLW DESIGN//
		alignItems: "center",
		padding: theme.spacing(0, 1),
		minHeight: "53px",
		justifyContent: "flex-start",
	},
	content: {
		display: "flex",
		// backgroundColor: theme.palette.bordabox, //DARK MODE PLW DESIGN//
		flexDirection: "column",
		padding: "8px 0px 8px 8px",
		height: "100%",
		overflowY: "scroll",
		...theme.scrollbarStyles,
	},

	contactAvatar: {
		margin: 15,
		width: 100,
		height: 100,
	},

	contactHeader: {
		display: "flex",
		padding: 8,
		margin: 8,
		// flexDirection: "column",
		alignItems: "center",
		justifyContent: "between",
		backgroundColor: theme.palette.inputBackgroundColor,
		"& > *": {
			margin: 4,
		},
		borderRadius: "16px",
	},

	contactNotes: {
		marginTop: 28,
		padding: 8,
		display: "flex",
		flexDirection: "column",
		backgroundColor: theme.palette.cardnormal,
		borderRadius: "16px",
		border: "none"
	},

	contactDetails: {
		marginTop: 8,
		padding: 8,
		display: "flex",
		flexDirection: "column",
		backgroundColor: theme.palette.cardnormal,
		border: "none"
	},
	contactExtraInfo: {
		marginTop: 4,
		padding: 6,
		backgroundColor: theme.palette.cardnormal,
		border: "none",
		borderBottom: `1px solid ${theme.palette.divider}`,
	},
}));


const ContactDrawer = ({ open, handleDrawerClose, contact, ticket, loading }) => {
  const classes = useStyles();

  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
		setModalOpen(false);
  }, [open, contact]);
	

  return (
    <>
      {/* Componente Drawer para exibir as informações de contato ao lado direito */}
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="right"
        open={open}
        PaperProps={{ style: { position: "absolute" } }}
        BackdropProps={{ style: { position: "absolute" } }}
        ModalProps={{
          container: document.getElementById("drawer-container"),
          style: { position: "absolute" },
        }}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        {/* Header do Drawer com botão de fechar e título */}
        <div className={classes.header}>
          <IconButton onClick={handleDrawerClose}>
            <CloseIcon />
          </IconButton>
          <Typography style={{ justifySelf: "center", fontSize: "16px" }}>
            {i18n.t("contactDrawer.header")}
          </Typography>
        </div>
        
        {/* Exibe esqueleto de carregamento enquanto os dados estão sendo carregados */}
        {loading ? (
          <ContactDrawerSkeleton classes={classes} />
        ) : (
          <div className={classes.content}>
            {/* Exibe informações do contato, como nome, telefone e e-mail */}
            <Paper square variant="outlined" className={classes.contactHeader}>
              <CardHeader
                onClick={() => setModalOpen(true)} // Abre o modal de edição ao clicar no header
                style={{ cursor: "pointer", width: "100%", padding: 2 }}
                titleTypographyProps={{ noWrap: true }}
                subheaderTypographyProps={{ noWrap: true }}
                avatar={
                  <Avatar
                    src={contact.profilePicUrl}
                    alt="contact_image"
                    style={{ width: 40, height: 40 }}
                  />
                }
                title={
                  <>
                    <Typography>{contact.name}</Typography>
                  </>
                }
                subheader={
                  <>
                    <Typography style={{ fontSize: 12 }}>
                      <Link href={`tel:${contact.number}`}>{contact.number}</Link>
                    </Typography>
                    <Typography style={{ fontSize: 12 }}>
                      <Link href={`mailto:${contact.email}`}>{contact.email}</Link>
                    </Typography>
                  </>
                }
              />
              {/* Ícone de edição que abre o modal ao ser clicado */}
              <IconButton onClick={() => setModalOpen(true)} style={{ marginLeft: 5 }}>
                <CreateIcon />
              </IconButton>
            </Paper>
            
            {/* Notas do contato associadas ao ticket */}
            <Paper square variant="outlined" className={classes.contactNotes}>
              <ContactNotes ticket={ticket} />
            </Paper>
            
            {/* Detalhes adicionais do contato */}
            {/* <Paper square variant="outlined" className={classes.contactDetails}>
              <Typography variant="subtitle1" style={{ marginBottom: 10 }}>
                {i18n.t("ticketOptionsMenu.appointmentsModal.title")}
              </Typography>
            </Paper> */}
            
            {/* Exibição de informações extras do contato */}
            <Paper square variant="outlined" className={classes.contactDetails}>
              <ContactModal
                open={modalOpen}
                onClose={() => setModalOpen(false)}
                contactId={contact.id}
              />
              <Typography variant="subtitle1">
                {i18n.t("contactDrawer.extraInfo")}
              </Typography>
              {contact?.extraInfo?.map((info) => (
                <Paper
                  key={info.id}
                  square
                  variant="outlined"
                  className={classes.contactExtraInfo}
                >
                  <InputLabel>{info.name}</InputLabel>
                  <Typography component="div" noWrap style={{ paddingTop: 1 }}>
                    <MarkdownWrapper>{info.value}</MarkdownWrapper>
                  </Typography>
                </Paper>
              ))}
            </Paper>
          </div>
        )}
      </Drawer>

    </>
  );
};

export default ContactDrawer;