import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
    inline: {
        width: '100%'
    },

    container:{
    display: "flex", 
    flexDirection: "column", 
    alignItems: "flex-start", 
    borderBottom: `1px solid ${theme.palette.divider}`,
    paddingBottom: 16,
    marginBottom: 8,
    },

    containerUser: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
        gap: 8,
    },
    name: {
        fontSize: 12,
        color: "grey",
    },
    note: {
        fontSize: 16,
        color: theme.palette.text.primary,
    }

}));

export default function ContactNotesDialogListItem (props) {
    const { note, deleteItem } = props;
    const classes = useStyles();

    const handleDelete = (item) => {
        deleteItem(item);
    }

    return (
        <ListItem className={classes.container} alignItems="flex-start">
            <div className={classes.containerUser}>
            <Typography className={classes.name}>{note.user.name}, {moment(note.createdAt).format('DD/MM/YY HH:mm')}</Typography>
            <IconButton onClick={() => handleDelete(note)} edge="end" aria-label="delete" style={{padding: 4}}>
                <DeleteIcon fontSize='small' />
            </IconButton>
            </div>
            <Typography className={classes.note}>{note.note}</Typography>
        </ListItem>
    )   
}

ContactNotesDialogListItem.propTypes = {
    note: PropTypes.object.isRequired,
    deleteItem: PropTypes.func.isRequired
}