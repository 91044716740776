import React from "react";
import { Divider, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    margin: theme.spacing(2, 0),
  },
  divider: {
    flex: 1,
    // backgroundColor: "#5c5c5c", // Cor da linha
  },
  text: {
    margin: theme.spacing(0, 2),
    color: "#bcbcbc", // Cor do texto
    fontSize: "12px", // Tamanho da fonte para ficar semelhante ao da imagem
  },
}));

const DividerWithText = ({ children }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Divider className={classes.divider} />
      <Typography className={classes.text}>{children}</Typography>
      <Divider className={classes.divider} />
    </div>
  );
};

export default DividerWithText;
