import api, { openApi } from "../../services/api";

const usePlans = () => {

    const getPlanList = async (params) => {
        const { data } = await openApi.request({
            url: '/plans/list',
            method: 'GET',
            params
        });
        return data;
    }

    const list = async (params) => {
        const { data } = await api.request({
            url: '/plans/all',
            method: 'GET',
            params
        });
        return data;
    }

    const listSearch = async (searchParam = "", pageNumber = 1) => {
        const { data } = await api.request({
            url: `/plans`,
            method: 'GET',
            params: {
                searchParam,  // Passa o parâmetro de busca opcional
                pageNumber    // Passa o número da página opcional (valor padrão: 1)
            }
        });
        return data;
    };

    const finder = async (id) => {
        const { data } = await api.request({
            url: `/plans/${id}`,
            method: 'GET'
        });
        return data;
    }

    const save = async (data) => {
        const { data: responseData } = await api.request({
            url: '/plans',
            method: 'POST',
            data
        });
        return responseData;
    }

    const update = async (data) => {
        const { data: responseData } = await api.request({
            url: `/plans/${data.id}`,
            method: 'PUT',
            data
        });
        return responseData;
    }

    const remove = async (id) => {
        const { data } = await api.request({
            url: `/plans/${id}`,
            method: 'DELETE'
        });
        return data;
    }

    const getPlanCompany = async (params, id) => {
        const { data } = await api.request({
            url: `/companies/listPlan/${id}`,
            method: 'GET',
            params
        });
        return data;
    }

    return {
        getPlanList,
        list,
        save,
        update,
        finder,
        remove,
        getPlanCompany,
        listSearch
    }
}

export default usePlans;