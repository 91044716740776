import React from "react";
import { Chip, makeStyles } from "@material-ui/core";
import { i18n } from "../../translate/i18n";
import OutlinedDiv from "../OutlinedDiv";
import {

    Typography,

} from "@material-ui/core";
const useStyles = makeStyles(theme => ({
    containerChips:{
        display: 'flex',
        gap:'8px',
        flexWrap: 'wrap',
    },
    chip: {
        // margin: theme.spacing(0.5),
        cursor: "pointer",
        border: theme.palette.type === 'light' ? '1px solid #DBDBDB' : '1px solid #53556C',
        height: '30px',
        backgroundColor: theme.palette.type === 'light' ? '#F2F2F2' : '#262945',
        padding: '8px 10px',
        fontWeight: '600',
    }
}));

const MessageVariablesPicker = ({ onClick, disabled }) => {
    const classes = useStyles();

    const handleClick = (e, value) => {
        e.preventDefault();
        if (disabled) return;
        onClick(value);
    };

    const msgVars = [
        {
            name: i18n.t("messageVariablesPicker.vars.contactFirstName"),
            value: "{{firstName}}"
        },
        {
            name: i18n.t("messageVariablesPicker.vars.contactName"),
            value: "{{name}} "
        },
        {
            name: i18n.t("messageVariablesPicker.vars.greeting"),
            value: "{{ms}} "
        },
        {
            name: i18n.t("messageVariablesPicker.vars.protocolNumber"),
            value: "{{protocol}} "
        },
        {
            name: i18n.t("messageVariablesPicker.vars.hour"),
            value: "{{hora}} "
        },
    ];

    return (
        <>
            <Typography style={{
                fontWeight: '600',
                fontSize: '14px',
            }}>{i18n.t("messageVariablesPicker.label")}</Typography>
            <Typography color="textSecondary" style={{
                fontWeight: '400',
                fontSize: '13px',
                marginBottom: '12px',
            }}>{i18n.t("messageVariablesPicker.sublabel")}</Typography>
            <div className={classes.containerChips}>
            {msgVars.map(msgVar => (
                <Chip
                    key={msgVar.value}
                    onMouseDown={e => handleClick(e, msgVar.value)}
                    label={msgVar.name}
                    size="small"
                    className={classes.chip}
                    style={{ marginTop: '10px' }}
                // color="primary"
                />
            ))}</div>
        </>
    );
};

export default MessageVariablesPicker;