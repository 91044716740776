import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import ButtonWithText from "../ButtonWithText";
import { i18n } from "../../translate/i18n";

const useStyles = makeStyles((theme) => ({
  cardWrapper: {
    margin: "14px auto 0",
    padding: "8px 16px",
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: `${theme.palette.type === 'dark' ? theme.palette.cardnormal : "#fff"} !important`,
    borderRadius: '18px',
    width: "100%",
    border: `1px solid ${theme.palette.divider}`,
  },
  numberText: {
    fontSize: '24px',
    fontWeight: 'bold',
    color: "#6EB131", //theme.palette.primary.main, // Usando a cor primária do tema
  },
  text: {
    fontSize: '16px',
    fontWeight: 400,
    color: theme.palette.text.primary,
  },
  button: {
    backgroundColor: "#6EB131", //theme.palette.primary.main,
    color: "#fff", //theme.palette.primary.contrastText,
    fontWeight: 'bold',
    textTransform: 'none',
    borderRadius: '24px',
    padding: '8px 24px',
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
}));

const CustomersWaiting = ({ pendingCount, onAttendClick }) => {
  const classes = useStyles();

  return (
    <Paper className={classes.cardWrapper} elevation={0}>
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: "8px" }}>
        <Typography className={classes.numberText}>
          {String(pendingCount).padStart(2, '0')}
        </Typography>
        <Typography className={classes.text}>
          &nbsp;{i18n.t("customersWaiting.title")}
        </Typography>
      </div>
      <ButtonWithText
        size="small"
        variant="contained"
        color="primary"
        onClick={onAttendClick}
        style={{borderRadius: "24px"}}
      >
        {i18n.t("customersWaiting.buttons.to_meeting")}
      </ButtonWithText>
    </Paper>
  );
};

export default CustomersWaiting;
