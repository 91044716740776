import React, { useContext, useEffect, useState } from "react";

import { toast } from "react-toastify";
import api from "../../services/api";
import toastError from "../../errors/toastError";

import {
  Box,
  Button,
  Dialog,
  makeStyles,
  DialogTitle,
  DialogActions,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { AuthContext } from "../../context/Auth/AuthContext";
import MessagesList from "../MessagesList";
import { ReplyMessageProvider } from "../../context/ReplyingMessage/ReplyingMessageContext";
import TicketHeader from "../TicketHeader";
import TicketInfo from "../TicketInfo";
import { SocketContext } from "../../context/Socket/SocketContext";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Paper from "@material-ui/core/Paper";
import TransferTicketModalCustom from "../TransferTicketModalCustom";
import ConfirmationModal from "../ConfirmationModal";

import { Can } from "../Can";
import ButtonWithText from "../ButtonWithText";

import { i18n } from "../../translate/i18n";

const drawerWidth = 320;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    height: "100%",
    position: "relative",
    overflow: "hidden",
  },

  titleContainer: {
    position: "relative",
    padding: 0,
	},
  closeIcon: {
    position: "absolute",
    right: 4,
    top: "20%",
  },

  mainWrapper: {
    flex: 1,
    height: "100%",
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    borderLeft: "0",
    marginRight: -drawerWidth,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },

  mainWrapperShift: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },

  mainPendingWrapper: {
    backgroundColor: theme.palette.type === 'dark' ? theme.palette.cardnormal : theme.palette.tabHeaderBackground,
    display: "flex",
    alignItems: "center",
    justifyContent: "end",
    padding: "8px 16px",
    gap: "8px",
  },

  DeleteButton: {
    backgroundColor: "#E25451 !important",
    color: theme.palette.contrastText,
    border: `1px solid ${theme.palette.divider}`,
    padding: "8px 18px", // Espaçamento interno
    borderRadius: "16px", // Bordas arredondadas para combinar com a imagem
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textTransform: "none", // Texto sem transformação
    fontSize: "14px", // Tamanho da fonte ajustado
    fontWeight: 600,
    "&:hover": {
      backgroundColor: theme.palette.primary.dark, // Cor mais escura no hover
    },
    "&:disabled": {
      backgroundColor: theme.palette.action.disabledBackground, // Cor para quando o botão estiver desabilitado
    },
  },

  TransferButton: {
    backgroundColor: `${theme.palette.type === 'dark' ? theme.palette.cardnormal : "grey"} !important`,
    color: theme.palette.text.primary,
    border: `1px solid ${theme.palette.divider}`,
    padding: "8px 18px", // Espaçamento interno
    borderRadius: "16px", // Bordas arredondadas para combinar com a imagem
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textTransform: "none", // Texto sem transformação
    fontSize: "14px", // Tamanho da fonte ajustado
    fontWeight: 600,
    "&:hover": {
      backgroundColor: theme.palette.primary.dark, // Cor mais escura no hover
    },
    "&:disabled": {
      backgroundColor: theme.palette.action.disabledBackground, // Cor para quando o botão estiver desabilitado
    },
  },

  AttendButton: {
    backgroundColor: `#6EB131 !important`,
    color: theme.palette.contrastText,
    padding: "8px 18px", // Espaçamento interno
    borderRadius: "16px", // Bordas arredondadas para combinar com a imagem
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textTransform: "none", // Texto sem transformação
    fontSize: "14px", // Tamanho da fonte ajustado
    fontWeight: 600,
    boxShadow: "none",
    "&:hover": {
      backgroundColor: theme.palette.primary.dark, // Cor mais escura no hover
    },
    "&:disabled": {
      backgroundColor: theme.palette.action.disabledBackground, // Cor para quando o botão estiver desabilitado
    },
  },
}));

export default function TicketMessagesDialog({ open, handleClose, ticketId }) {
  const history = useHistory();
  const classes = useStyles();

  const { user } = useContext(AuthContext);

  const [, setDrawerOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [contact, setContact] = useState({});
  const [ticket, setTicket] = useState({});
	const [transferTicketModalOpen, setTransferTicketModalOpen] = useState(false);
  const [confirmationOpen, setConfirmationOpen] = useState(false);

  const socketManager = useContext(SocketContext);

  useEffect(() => {
    let delayDebounceFn = null;
    if (open) {
      setLoading(true);
      delayDebounceFn = setTimeout(() => {
        const fetchTicket = async () => {
          try {
            const { data } = await api.get("/tickets/" + ticketId);
            const { queueId } = data;
            const { queues, profile } = user;

            const queueAllowed = queues.find((q) => q.id === queueId);
            if (queueAllowed === undefined && profile !== "admin") {
              toast.error("Acesso não permitido");
              history.push("/tickets");
              return;
            }

            setContact(data.contact);
            setTicket(data);
            setLoading(false);
          } catch (err) {
            setLoading(false);
            toastError(err);
          }
        };
        fetchTicket();
      }, 500);
    }
    return () => {
      if (delayDebounceFn !== null) {
        clearTimeout(delayDebounceFn);
      }
    };
  }, [ticketId, user, history, open]);

  useEffect(() => {
    const companyId = localStorage.getItem("companyId");
    let socket = null;

    if (open) {
      const socket = socketManager.getSocket(companyId);
      socket.on("ready", () => socket.emit("joinChatBox", `${ticket.id}`));

      socket.on(`company-${companyId}-ticket`, (data) => {
        if (data.action === "update" && data.ticket.id === ticket.id) {
          setTicket(data.ticket);
        }

        if (data.action === "delete" && data.ticketId === ticket.id) {
          // toast.success("Ticket deleted sucessfully.");
          history.push("/tickets");
        }
      });

      socket.on(`company-${companyId}-contact`, (data) => {
        if (data.action === "update") {
          setContact((prevState) => {
            if (prevState.id === data.contact?.id) {
              return { ...prevState, ...data.contact };
            }
            return prevState;
          });
        }
      });
    }

    return () => {
      if (socket !== null) {
        socket.disconnect();
      }
    };
  }, [ticketId, ticket, history, open, socketManager]);

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const renderTicketInfo = () => {
    if (ticket.user !== undefined) {
      return (
        <TicketInfo
          contact={contact}
          ticket={ticket}
          onClick={handleDrawerOpen}
        />
      );
    }
  };

  const renderMessagesList = () => {
    return (
      <Box className={classes.root}>
        <MessagesList
          ticket={ticket}
          ticketId={ticket.id}
          isGroup={ticket.isGroup}
        ></MessagesList>
      </Box>
    );
  };

  const handleUpdateTicketStatus = async (e, status, userId) => {
		setLoading(true);
		try {
			await api.put(`/tickets/${ticketId}`, {
				status: status,
				userId: userId || null,
			});

			setLoading(false);
			if (status === "open") {
				history.push(`/tickets/${ticketId}`);
			} else {
				history.push("/tickets");
			}
		} catch (err) {
			setLoading(false);
			toastError(err);
		}
	};

  const handleOpenTransferModal = e => {
		setTransferTicketModalOpen(true);
	};

  const handleDeleteTicket = async () => {
		try {
			await api.delete(`/tickets/${ticketId}`);
		} catch (err) {
			toastError(err);
		}
	};

  const handleCloseTransferTicketModal = () => {
    setTransferTicketModalOpen(false);
	};

  const handleOpenConfirmationModal = e => {
		setConfirmationOpen(true);
	};
  console.log(ticket)

  return (
    <>
    <Dialog maxWidth="md" onClose={handleClose} open={open}>
      <DialogTitle  disableTypography id="form-dialog-title" className={classes.titleContainer}>
        <TicketHeader loading={loading}>{renderTicketInfo()}</TicketHeader>
        <IconButton onClick={handleClose} className={classes.closeIcon}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <ReplyMessageProvider>{renderMessagesList()}</ReplyMessageProvider>
      {/* {ticket.status === "pending" && (
        <DialogActions>
          <Paper square elevation={0} className={classes.mainPendingWrapper}>
          <Can
              role={user.profile}
              perform="ticket-options:deleteTicket"
              yes={() => (
            <ButtonWithText
            size="small"
            variant="contained"
            color="primary"
            onClick={handleOpenConfirmationModal}
            className={classes.DeleteButton}
          >
            {i18n.t('messagesInput.pendding.delete')}
          </ButtonWithText>)} />
          <ButtonWithText
            size="small"
            variant="contained"
            color="primary"
            onClick={handleOpenTransferModal}
            className={classes.TransferButton}
          >
            {i18n.t('messagesInput.pendding.transfer')}
          </ButtonWithText>
          <ButtonWithText
          loading={loading}
            size="small"
            variant="contained"
            color="primary"
            onClick={e => handleUpdateTicketStatus(e, "open", user?.id)}
            className={classes.AttendButton}
          >
            {i18n.t('messagesInput.pendding.attend')}
          </ButtonWithText>
          </Paper>
          
          <TransferTicketModalCustom
          modalOpen={transferTicketModalOpen}
          onClose={handleCloseTransferTicketModal}
          ticketid={ticketId}
        />

        <ConfirmationModal
          title={i18n.t('messagesInput.pendding.confirmationTitle', { ticketId })}
          open={confirmationOpen}
          onClose={setConfirmationOpen}
          onConfirm={handleDeleteTicket}
        >
          {i18n.t('messagesInput.pendding.confirmationMessage')}
        </ConfirmationModal>
      </DialogActions>)
      } */}
    </Dialog>
    <TransferTicketModalCustom
      modalOpen={transferTicketModalOpen}
      onClose={handleCloseTransferTicketModal}
      ticketid={ticketId}
    />

    <ConfirmationModal
      title={i18n.t('messagesInput.pendding.confirmationTitle', { ticketId })}
      open={confirmationOpen}
      onClose={setConfirmationOpen}
      onConfirm={handleDeleteTicket}
    >
      {i18n.t('messagesInput.pendding.confirmationMessage')}
    </ConfirmationModal>
    </>
  );
}
