import React from "react";
import { Button } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

const useStyles = makeStyles((theme) => ({
  button: {
    backgroundColor: `#6EB131 !important`,
    color: "#fff", // Texto branco do tema
    padding: "8px 18px", // Espaçamento interno
    borderRadius: "16px", // Bordas arredondadas para combinar com a imagem
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textTransform: "none", // Texto sem transformação
    fontSize: "14px", // Tamanho da fonte ajustado
    fontWeight: "bold",
    "&:hover": {
      backgroundColor: theme.palette.primary.dark, // Cor mais escura no hover
    },
    "&:disabled": {
      backgroundColor: theme.palette.action.disabledBackground, // Cor para quando o botão estiver desabilitado
    },
  },
  icon: {
    marginRight: theme.spacing(1), // Espaçamento entre ícone e texto
    fontSize: "24px", // Tamanho do ícone ajustado
  },
}));

const ButtonWithText = ({ loading, children, ...rest }) => {
  const classes = useStyles();
  const theme = useTheme(); // Acessa o tema aqui

  return (
    <Button className={classes.button} {...rest} disabled={loading}>
      {children}
    </Button>
  );
};

export default ButtonWithText;
