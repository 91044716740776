import React, { useState, useContext, useEffect } from "react";
import clsx from "clsx";
import {
  makeStyles,
  Drawer,
  AppBar,
  Toolbar,
  List,
  Typography,
  Divider,
  IconButton,
  MenuItem,
  Menu,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";
import { FaSun } from "react-icons/fa6";
import { FaMoon } from "react-icons/fa";
import MenuIcon from "@material-ui/icons/Menu";
import { BsLayoutSidebar } from "react-icons/bs";
import AccountCircle from "@material-ui/icons/AccountCircle";
import CachedIcon from "@material-ui/icons/Cached";
import MainListItems from "./MainListItems";
import NotificationsPopOver from "../components/NotificationsPopOver";
import NotificationsVolume from "../components/NotificationsVolume";
import UserModal from "../components/UserModal";
import { AuthContext } from "../context/Auth/AuthContext";
import BackdropLoading from "../components/BackdropLoading";
import AnnouncementsPopover from "../components/AnnouncementsPopover";
import { i18n } from "../translate/i18n";
import toastError from "../errors/toastError";
import { SocketContext } from "../context/Socket/SocketContext";
import { useDate } from "../hooks/useDate";
import logo from "../assets/logo.png";
import ColorModeContext from "../layout/themeContext";
import './buttonmode.css'


const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    height: "100vh",
    backgroundColor: theme.palette.fancyBackground,
  },
  avatar: {
    width: "100%",
  },
  iconDark: {
    color: 'white',
  },
  iconLight: {
    color: 'black',
  },
  toolbar: {
    paddingRight: 24,
    color: theme.palette.textPrimary,
    background: theme.palette.fancyBackground,
    borderBottom: "none", // Remove a borda inferior
    boxShadow: "none", // Remove a sombra
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center", // Centraliza o botão no sidebar colapsado
    // padding: "0 16px",
    filter: theme.palette.type === 'dark' ? 'invert(1)' : 'none',
    height: "68px",
    [theme.breakpoints.down("sm")]: {
      height: "68px",
    },
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    borderRadius: '16px',
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: `calc(100% - ${theme.spacing(7)}px)`,
    marginLeft: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${theme.spacing(9)}px)`,
      marginLeft: theme.spacing(9),
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%", // No modo mobile, o AppBar ocupa 100% da largura
      marginLeft: 0,
    },
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    filter: theme.palette.type === 'dark' ? 'invert(1)' : 'none', // Verifica se o modo é escuro e aplica o filtro
    marginRight: 0,
    [theme.breakpoints.up("sm")]: {
      display: "block", // Mostrar o botão de ocultar em telas maiores
    },
    [theme.breakpoints.down("sm")]: {
      display: "block", // Exibir o botão de menu no mobile
      marginLeft: -12, // Ajustar o posicionamento
    },
  },
  title: {
    flexGrow: 1,
    fontSize: 14,
    color: theme.palette.light.main,
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    background: theme.palette.sidebar,
    borderRadius: '16px',
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    [theme.breakpoints.down('sm')]: {
      background: `${theme.palette.sidebarMobile}`, // Aplicando 100% de opacidade no mobile
    },
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: {
    minHeight: "48px",
  },
  content: {
    flex: 1,
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  containerWithScroll: {
    flex: 1,
    padding: theme.spacing(1),
    borderRadius: '24px',
    overflowY: "auto",
    ...theme.scrollbarStyles,
  },
  logo: {
    width: "80%",
    height: "auto",
    padding: "24px",
    maxWidth: 180,
    [theme.breakpoints.down("sm")]: {
      width: "auto",
      height: "auto",
      maxWidth: 180,
    },
    // filter: theme.palette.type === 'dark' ? 'invert(1)' : 'none', // Verifica se o modo é escuro e aplica o filtro
  },
}));

const LoggedInLayout = ({ children }) => {
  const classes = useStyles();
  const [userModalOpen, setUserModalOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const { handleLogout, loading, user } = useContext(AuthContext);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const theme = useTheme();
  const { colorMode } = useContext(ColorModeContext);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [volume, setVolume] = useState(localStorage.getItem("volume") || 1);
  const { dateToClient } = useDate();
  const socketManager = useContext(SocketContext);
  const [urlUser, setUrlUser] = useState(null);

  // Verifique se os dados do usuário estão carregando
  const isUserLoading = !user || loading;

  useEffect(() => {
    if (document.body.offsetWidth > 1200) {
      setDrawerOpen(true);
    }
  }, []);

  useEffect(() => {
    if (user && user.mediaPath) {
      setUrlUser(user.mediaPath); // Atualiza a URL da imagem quando o user.mediaPath estiver disponível
    }
  }, [user]);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
    setMenuOpen(true);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setMenuOpen(false);
  };

  const handleOpenUserModal = () => {
    setUserModalOpen(true);
    handleCloseMenu();
  };

  const handleClickLogout = () => {
    handleCloseMenu();
    handleLogout();
  };

  const drawerClose = () => {
    if (isMobile) {
      setDrawerOpen(false);
    }
  };

  const handleRefreshPage = () => {
    window.location.reload(false);
  }

  const toggleColorMode = () => {
    colorMode.toggleColorMode();
  }

  if (loading) {
    return <BackdropLoading />;
  }

  return (
    <div className={classes.root}>
      <Drawer
        variant={isMobile ? "temporary" : "permanent"}
        className={clsx({
          [classes.drawerPaper]: !isMobile,
          [classes.drawerPaperClose]: !drawerOpen && !isMobile,
        })}
        classes={{
          paper: clsx(
            classes.drawerPaper,
            !drawerOpen && classes.drawerPaperClose
          ),
        }}
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
      >
        <div className={classes.toolbarIcon}>
          {!drawerOpen && !isMobile && (
            <IconButton
              onClick={() => setDrawerOpen(true)}
              className={classes.menuButton}
            >
              <BsLayoutSidebar
                color="black"
                style={{ filter: theme.palette.type === 'dark' ? 'invert(1)' : 'none' }}
              />
            </IconButton>
          )}
          {drawerOpen && (
            <>
              <img src={logo} className={classes.logo} alt="logo" />
              <IconButton
                onClick={() => setDrawerOpen(false)}
                className={classes.menuButton}
              >
                <BsLayoutSidebar
                  color="black"
                  style={{ filter: theme.palette.type === 'dark' ? 'invert(1)' : 'none' }}
                />
              </IconButton>
            </>
          )}
        </div>

        <Divider />
        <List className={classes.containerWithScroll}>
          <MainListItems drawerClose={drawerClose} collapsed={!drawerOpen} />
        </List>
        <Divider />
      </Drawer>
      <UserModal
        open={userModalOpen}
        onClose={() => setUserModalOpen(false)}
        userId={user?.id}
      />
      <AppBar
        position="absolute"
        className={clsx(classes.appBar, drawerOpen && !isMobile && classes.appBarShift)}
        color="primary"
        style={{ borderBottom: "none", boxShadow: "none" }} // Remove borda e sombra
      >
        <Toolbar variant="dense" className={classes.toolbar} style={{ gap: '14px' }}>
          {!drawerOpen && isMobile && (
            <IconButton
              edge="start"
              variant="contained"
              aria-label="open drawer"
              onClick={() => setDrawerOpen(true)}
              className={classes.menuButton}

            >
              <MenuIcon style={{ filter: theme.palette.type === 'dark' ? 'invert(1)' : 'none' }} />
            </IconButton>
          )}

          <Typography
            component="h2"
            variant="h6"
            color="inherit"
            noWrap
            className={classes.title}
            style={{ color: theme.mode === 'light' ? "#0A0A0A" : "white" }}
          >
            {/* {user?.profile === "admin" && user?.company?.dueDate ? (
              <>
                Olá <b>{user.name}</b>, Bem vindo a <b>{user?.company?.name}</b>! (Ativo até {dateToClient(user?.company?.dueDate)})
              </>
            ) : (
              <>
                Olá  <b>{user.name}</b>, Bem vindo a <b>{user?.company?.name}</b>!
              </>
            )} */}
          </Typography>

          {/* <IconButton edge="start" onClick={toggleColorMode} style={{ color: theme.mode === 'light' ? "#0A0A0A" : "white", borderRadius: '40px', padding: '8px' }}>
            {theme.mode === 'light' ? <FaMoon style={{ width: '18px', height: '18px' }} /> : <FaSun style={{ width: '16px', height: '16px' }} />}
          </IconButton> */}



          <label className="switch">
            <input
              type="checkbox"
              onChange={toggleColorMode}  // Conecta o toggleColorMode ao evento de mudança
              checked={theme.palette.type === 'dark'}  // Verifica o estado do tema
            />
            <span className="slider"></span>
          </label>


          <NotificationsVolume
            setVolume={setVolume}
            volume={volume}
          />
          <IconButton
            onClick={handleRefreshPage}
            aria-label={i18n.t("mainDrawer.appBar.refresh")}
            style={{ color: theme.mode === 'light' ? "#0A0A0A" : "white", borderRadius: '40px', padding: '8px' }}>
            <CachedIcon style={{ width: '18px', height: '18px' }} />
          </IconButton>

          {user.id && <NotificationsPopOver volume={volume} style={{ color: theme.mode === 'light' ? "#0A0A0A" : "white", borderRadius: '40px', padding: '8px' }} />}

          <AnnouncementsPopover />

          <div>
            {/* <IconButton
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              variant="contained"
              style={{ color: theme.mode === 'light' ? "#0A0A0A" : "white", borderRadius: '40px', padding: '8px' }}>

              <AccountCircle style={{ width: '18px', height: '18px' }} />
            </IconButton> */}
             <IconButton
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              variant="contained"
              style={{ color: theme.mode === 'light' ? "#0A0A0A" : "white", borderRadius: '40px', padding: '8px' }}
            >
              {isUserLoading ? (
                <div style={{ width: '32px', height: '32px', borderRadius: '50%', backgroundColor: 'gray' }} />
              ) : urlUser ? (
                <img
                  src={urlUser}
                  alt="User Avatar"
                  style={{ width: '32px', height: '32px', borderRadius: '50%' }}
                />
              ) : (
                <AccountCircle style={{ width: '18px', height: '18px' }} />
              )}
            </IconButton>
           

            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              getContentAnchorEl={null}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              style={{ color: theme.mode === 'light' ? "#0A0A0A" : "white" }}
              open={menuOpen}
              onClose={handleCloseMenu}
            >
              {/* perfil */}
              <MenuItem onClick={handleOpenUserModal}>
                {i18n.t("mainDrawer.appBar.user.profile")}
              </MenuItem>
              <MenuItem onClick={handleClickLogout}>
                {i18n.t("mainDrawer.appBar.user.logout")}
              </MenuItem>
            </Menu>
          </div>
        </Toolbar>

      </AppBar>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />

        {children ? children : null}
      </main>
    </div>
  );
};

export default LoggedInLayout;
