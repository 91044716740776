const messages = {
  pt: {
    translations: {
      signup: {
        title: "Crie a sua conta",
        subtitle: "Insira seus dados e crie sua conta",
        toasts: {
          success: "Usuário criado com sucesso! Faça seu login!!!.",
          fail: "Erro ao criar usuário. Verifique os dados informados.",
        },
        form: {
          name: "Nome",
          email: "Email",
          password: "Senha",
        },
        buttons: {
          submit: "Cadastrar",
          login: "Já tem uma conta? Entre!",
        },
      },
      login: {
        title: "Faça seu login",
        subtitle: "Insira seus dados e faça login",
        forgtitle: "Redefinir",
        forgtsubtitle: "Insira seus dados para recuperar sua senha",
        forget: "Esqueci minha senha",
        form: {
          email: "Digite o seu email",
          password: "Digite sua senha",
        },
        buttons: {
          submit: "Entrar",
          register: "Não tenho conta",
        },
      },
      plans: {
        form: {
          name: "Nome",
          users: "Usuários",
          connections: "Canais",
          campaigns: "Campanhas",
          schedules: "Agendamentos",
          enabled: "Habilitadas",
          disabled: "Desabilitadas",
          clear: "Cancelar",
          delete: "Excluir",
          save: "Salvar",
          yes: "Sim",
          no: "Não",
          money: "R$",
        },
      },
      companies: {
        title: "Cadastrar Empresa",
        form: {
          name: "Nome da Empresa",
          plan: "Plano",
          token: "Token",
          submit: "Cadastrar",
          success: "Empresa criada com sucesso!",
        },
      },
      auth: {
        toasts: {
          success: "Login efetuado com sucesso!",
        },
        token: "Token",
      },
      dashboard: {
        charts: {
          perDay: {
            title: "Atendimentos hoje: ",
          },
        },
      },
      connections: {
        title: "Canais",
        toasts: {
          deleted: "Conexão com o WhatsApp excluída com sucesso!",
        },
        confirmationModal: {
          deleteTitle: "Deletar",
          deleteMessage: "Você tem certeza? Essa ação não pode ser revertida.",
          disconnectTitle: "Desconectar",
          disconnectMessage:
            "Tem certeza? Você precisará ler o QR Code novamente.",
        },
        buttons: {
          add: "Adicionar WhatsApp",
          disconnect: "desconectar",
          tryAgain: "Tentar novamente",
          qrcode: "QR CODE",
          newQr: "Novo QR CODE",
          connecting: "Conectando",
        },
        toolTips: {
          disconnected: {
            title: "Falha ao iniciar sessão do WhatsApp",
            content:
              "Certifique-se de que seu celular esteja conectado à internet e tente novamente, ou solicite um novo QR Code",
          },
          qrcode: {
            title: "Esperando leitura do QR Code",
            content:
              "Clique no botão 'QR CODE' e leia o QR Code com o seu celular para iniciar a sessão",
          },
          connected: {
            title: "Conexão estabelecida!",
          },
          timeout: {
            title: "A conexão com o celular foi perdida",
            content:
              "Certifique-se de que seu celular esteja conectado à internet e o WhatsApp esteja aberto, ou clique no botão 'Desconectar' para obter um novo QR Code",
          },
        },
        table: {
          name: "Nome",
          status: "Status",
          lastUpdate: "Última atualização",
          default: "Padrão",
          actions: "Ações",
          session: "Sessão",
        },
      },
      whatsappModal: {
        title: {
          add: "Adicionar WhatsApp",
          edit: "Editar WhatsApp",
          text: "Integre seu WhatsApp e mantenha seu atendimento centralizado e organizado na Solução Chatsac.",
        },
        tabs: {
          general: "Geral",
          messages: "Mensagens",
          assessments: "Avaliações",
          integrations: "Integrações",
          schedules: "Horário de expediente",
        },
        form: {
          name: "Nome",
          default: "Padrão",
          sendIdQueue: "Setor",
          timeSendQueue: "Redirecionar para Setor em X minutos",
          queueRedirection: "Redirecionamento de Setor",
          outOfHoursMessage: "Mensagem de fora de expediente",
          queueRedirectionDesc: "Selecione um setor para os contatos que não possuem setores serem redirecionados",
          prompt: "Prompt",
          //maxUseBotQueues: "Enviar bot x vezes",
          //timeUseBotQueues: "Intervalo em minutos entre envio de bot",
          expiresTicket: "Encerrar chats abertos após x minutos",
          expiresInactiveMessage: "Mensagem de encerramento por inatividade",
          greetingMessage: "Mensagem de saudação",
          complationMessage: "Mensagem de conclusão",
          sendIdQueue: "Setor",
        },
        buttons: {
          okAdd: "Adicionar",
          okEdit: "Salvar",
          cancel: "Cancelar",
        },
        success: "WhatsApp salvo com sucesso.",
      },
      qrCode: {
        message: "Leia o QrCode para iniciar a sessão",
      },
      contacts: {
        title: "Contatos",
        toasts: {
          deleted: "Contato excluído com sucesso!",
          deletedAll: "Todos contatos excluídos com sucesso!",
        },
        searchPlaceholder: "Pesquisar...",
        confirmationModal: {
          deleteTitle: "Deletar ",
          deleteAllTitle: "Deletar Todos",
          importTitle: "Importar contatos",
          deleteMessage: "Tem certeza que deseja deletar este contato? Todos os tickets relacionados serão perdidos.",
          deleteAllMessage: "Tem certeza que deseja deletar todos os contatos? Todos os tickets relacionados serão perdidos.",
          importMessage: "Deseja importar todos os contatos do telefone?",
        },
        buttons: {
          import: "Importar Contatos",
          add: "Adicionar Contato",
          export: "Exportar Contatos",
          delete: "Excluir Todos Contatos"
        },
        table: {
          name: "Nome",
          whatsapp: "WhatsApp",
          email: "Email",
          actions: "Ações",
        },
      },
      queueIntegrationModal: {
        title: {
          add: "Adicionar projeto",
          edit: "Editar projeto",
        },
        form: {
          id: "ID",
          type: "Tipo",
          name: "Nome",
          projectName: "Nome do Projeto",
          language: "Linguagem",
          jsonContent: "JsonContent",
          urlN8N: "URL",
          typebotSlug: "Typebot - Slug",
          typebotExpires: "Tempo em minutos para expirar uma conversa",
          typebotKeywordFinish: "Palavra para finalizar o ticket",
          typebotKeywordRestart: "Palavra para reiniciar o fluxo",
          typebotRestartMessage: "Mensagem ao reiniciar a conversa",
          typebotUnknownMessage: "Mensagem de opção inválida",
          typebotDelayMessage: "Intervalo (ms) entre mensagens",
        },
        buttons: {
          okAdd: "Adicionar",
          okEdit: "Salvar",
          cancel: "Cancelar",
          test: "Testar Bot",
        },
        messages: {
          testSuccess: "Integração testada com sucesso!",
          addSuccess: "Integração adicionada com sucesso.",
          editSuccess: "Integração editada com sucesso.",
        },
      },
      sideMenu: {
        name: "Menu Lateral Inicial",
        note: "Se habilitado, o menu lateral irá iniciar fechado",
        options: {
          enabled: "Aberto",
          disabled: "Fechado",
        },
      },
      promptModal: {
        form: {
          name: "Nome",
          prompt: "Prompt",
          voice: "Voz",
          max_tokens: "Máximo de Tokens na resposta",
          temperature: "Temperatura",
          apikey: "API Key",
          max_messages: "Máximo de mensagens no Histórico",
          voiceKey: "Chave da API de Voz",
          voiceRegion: "Região de Voz",
        },
        success: "Prompt salvo com sucesso!",
        title: {
          add: "Adicionar Prompt",
          edit: "Editar Prompt",
        },
        buttons: {
          okAdd: "Adicionar",
          okEdit: "Salvar",
          cancel: "Cancelar",
        },
      },
      prompts: {
        title: "Prompts",
        table: {
          name: "Nome",
          queue: "Setor/Setores",
          max_tokens: "Máximo Tokens Resposta",
          actions: "Ações",
        },
        confirmationModal: {
          deleteTitle: "Excluir",
          deleteMessage: "Você tem certeza? Essa ação não pode ser revertida!",
        },
        buttons: {
          add: "Adicionar Prompt",
        },
      },
      contactModal: {
        title: {
          add: "Adicionar contato",
          edit: "Editar contato",
        },
        form: {
          mainInfo: "Dados do contato",
          extraInfo: "Informações adicionais",
          name: "Nome",
          number: "Número do Whatsapp",
          email: "Email",
          extraName: "Nome do campo",
          extraValue: "Valor",
          whatsapp: "Conexão Origem: "
        },
        buttons: {
          addExtraInfo: "Adicionar informação",
          okAdd: "Adicionar",
          okEdit: "Salvar",
          cancel: "Cancelar",
        },
        success: "Contato salvo com sucesso.",
      },
      queueModal: {
        title: {
          add: "Adicionar Setorr",
          edit: "Editar Setor",
        },
        form: {
          name: "Nome",
          color: "Cor",
          greetingMessage: "Mensagem de saudação",
          complationMessage: "Mensagem de conclusão",
          outOfHoursMessage: "Mensagem de fora de expediente",
          ratingMessage: "Mensagem de avaliação",
          token: "Token",
          orderQueue: "Ordem do Setor (Bot)",
          integrationId: "Integração",
          formOptions:{
            options: "Opções",
            addOption: "ADICIONAR",
            placeholderTitleOptions: "Título da opção",
            placeholderOptions: "Digite o texto da opção",
            titleIsNotdefined: "Título não definido",
          }
        },
        buttons: {
          okAdd: "Adicionar",
          okEdit: "Salvar",
          cancel: "Cancelar",
        },
      },
      userModal: {
        title: {
          add: "Adicionar usuário",
          edit: "Editar usuário",
        },
        form: {
          name: "Nome",
          email: "Email",
          password: "Senha",
          profile: "Perfil",
          whatsapp: "Conexão Padrão",

          allTicket: "Ticket Sem Setor [Invisível]",
          allTicketEnabled: "Habilitado",
          allTicketDesabled: "Desabilitado",
        },
        buttons: {
          okAdd: "Adicionar",
          okEdit: "Salvar",
          cancel: "Cancelar",
        },
        success: "Usuário salvo com sucesso.",
      },
      scheduleModal: {
        subtitle: "Otimize o atendimento dos seus clientes e ganhe tempo.",
        title: {
          add: "Novo Agendamento",
          edit: "Editar Agendamento",
        },
        form: {
          body: "Mensagem",
          contact: "Contato",
          sentAt: "Data de Envio",
          sendAtDate: "Data de agendamento",
          sendAtTime: "Hora de agendamento",
        },
        buttons: {
          okAdd: "Adicionar",
          okEdit: "Salvar",
          cancel: "Cancelar",
        },
        success: "Agendamento salvo com sucesso.",
      },
      tagModal: {
        title: {
          add: "Nova Tag",
          edit: "Editar Tag",
        },
        form: {
          name: "Nome",
          color: "Cor",
        },
        buttons: {
          okAdd: "Adicionar",
          okEdit: "Salvar",
          cancel: "Cancelar",
        },
        success: "Tag salvo com sucesso.",
      },
      chat: {
        noTicketMessage: "Selecione um ticket para começar a conversar.",
      },
      uploads: {
        titles: {
          titleUploadMsgDragDrop: "ARRASTE E SOLTE ARQUIVOS NO CAMPO ABAIXO",
          titleFileList: "Lista de arquivo(s)"
        },
      },
      ticketsManager: {
        buttons: {
          newTicket: "Novo",
        },
      },
      ticketsQueueSelect: {
        placeholder: "Setores",
      },
      tickets: {
        toasts: {
          deleted: "O atendimento que você estava foi deletado.",
        },
        notification: {
          message: "Mensagem de",
        },
        tabs: {
          open: { title: "Atendendo" },
          closed: { title: "Finalizados" },
          search: { title: "Busca" },
        },
        search: {
          placeholder: "Buscar atendimento e mensagens",
        },
        buttons: {
          showAll: "Todos",
        },
      },
      transferTicketModal: {
        title: "Transferir Ticket",
        fieldLabel: "Pesquise o seu usuário",
        fieldQueueLabel: "Transferir para setor",
        fieldQueuePlaceholder: "Selecione um setor",
        noOptions: "Nenhum usuário encontrado com esse nome",
        buttons: {
          ok: "Transferir",
          cancel: "Cancelar",
        },
      },
      ticketsList: {
        pendingHeader: "Aguardando",
        finished: "Finalizados",
        assignedHeader: "Atendendo",
        noTicketsTitle: "Nada aqui!",
        noTicketsMessage:
          "Nenhum atendimento encontrado com esse status ou termo pesquisado",
        buttons: {
          accept: "Aceitar",
          closed: "Finalizar",
          reopen: "Reabrir"
        },
      },
     //ate aqui o primeiro 
      customersWaiting: {
        title: "Clientes aguardando",
        buttons: {
          to_meeting: "Atender",
        },
      },
      newTicketModal: {
        title: "Criar Ticket",
        fieldLabel: "Digite para pesquisar o contato",
        add: "Adicionar",
        queue: "Selecione uma fila",
        connection: "Selecione uma conexão",  
        buttons: {
          ok: "Salvar",
          cancel: "Cancelar",
        },
      },
      mainDrawer: {
        listItems: {
          dashboard: "Dashboard",
          connections: "Canais",
          tickets: "Atendimentos",
          quickMessages: "Respostas Rápidas",
          contacts: "Contatos",
          queues: "Setores & Chatbot",
          tags: "Etiquetas",
          administration: "Administração",
          users: "Usuários",
          settings: "Configurações",
          helps: "Ajuda",
          messagesAPI: "API",
          schedules: "Agendamentos",
          campaigns: "Campanhas",
          annoucements: "Informativos",
          chats: "Chat Interno",
          financeiro: "Financeiro",
          files: "Lista de arquivos",
          prompts: "Open.Ai",
          queueIntegration: "Integrações",
        },
        menu:{
            listing: "Listagem",
            contacts_list: "Listas de Contatos",
            settings: "Configurações",
        }, 
        appBar: {
          notRegister: "Sem notificações",
          user: {
            profile: "Perfil",
            logout: "Sair",
          },
        },
      },
      queueIntegration: {
        title: "Integrações",
        table: {
          id: "ID",
          type: "Tipo",
          name: "Nome",
          projectName: "Nome do Projeto",
          language: "Linguagem",
          lastUpdate: "Ultima atualização",
          actions: "Ações",
        },
        buttons: {
          add: "Adicionar Projeto",
        },
        searchPlaceholder: "Pesquisar...",
        confirmationModal: {
          deleteTitle: "Excluir",
          deleteMessage:
            "Você tem certeza? Essa ação não pode ser revertida! e será removida dos setores e Canais vinculadas",
        },
      },
      files: {
        title: "Lista de arquivos",
        table: {
          name: "Nome",
          contacts: "Contatos",
          actions: "Ação",
        },
        toasts: {
          deleted: "Lista excluída com sucesso!",
          deletedAll: "Todas as listas foram excluídas com sucesso!",
        },
        buttons: {
          add: "Adicionar",
          deleteAll: "Deletar Todos",
        },
        confirmationModal: {
          deleteTitle: "Deletar",
          deleteAllTitle: "Deletar Todos",
          deleteMessage: "Tem certeza que deseja deletar esta lista?",
          deleteAllMessage: "Tem certeza que deseja deletar todas as listas?",
        },
      },
      messagesAPI: {
        title: "API",
        textMessage: {
          number: "Número",
          body: "Mensagem",
          token: "Token cadastrado"
        },
        mediaMessage: {
          number: "Número",
          body: "Nome do arquivo",
          media: "Arquivo",
          token: "Token cadastrado",
          noFileChosen: "Nenhum arquivo escolhido",
          chooseFile: "Escolher arquivo"
        },
        buttons:{
          send: "Enviar"
        },
        documentationTitle: "Documentação para envio de mensagens",
        sendMethods: "Métodos de Envio",
        methods: {
          textMessages: "Mensagens de Texto",
          mediaMessages: "Mensagens de Media"
        },
        instructionsTitle: "Instruções",
        importantNotes: "Observações importantes",
        notes: {
          beforeSending: "Antes de enviar mensagens, é necessário o cadastro do token vinculado à conexão que enviará as mensagens. Para realizar o cadastro acesse o menu 'Conexões', clique no botão editar da conexão e insira o token no devido campo.",
          numberFormat: "O número para envio não deve ter máscara ou caracteres especiais e deve ser composto por:",
          countryCode: "Código do país",
          areaCode: "DDD",
          number: "Número"
        },
        textMessagesTitle: "1. Mensagens de Texto",
        textInstructions: "Seguem abaixo a lista de informações necessárias para envio das mensagens de texto:",
        endpoint: "Endpoint",
        method: "Método",
        headers: "Headers",
        body: "Body",
        testSending: "Teste de Envio",
        mediaMessagesTitle: "2. Mensagens de Media",
        mediaInstructions: "Seguem abaixo a lista de informações necessárias para envio de media:",
        formData: "FormData",
        success: {
          sent: "Mensagem enviada com sucesso"
        },
        error: {
          noPermission: "Esta empresa não possui permissão para acessar essa página! Estamos lhe redirecionando."
        }

      },
      notifications: {
        noTickets: "Nenhuma notificação.",
      },
      quickMessages: {
        title: "Respostas Rápidas",
        searchPlaceholder: "Pesquisar...",
        noAttachment: "Sem anexo",
        confirmationModal: {
          deleteTitle: "Exclusão",
          deleteMessage: "Esta ação é irreversível! Deseja prosseguir?",
        },
        buttons: {
          add: "Adicionar",
          attach: "Anexar Arquivo",
          cancel: "Cancelar",
          edit: "Editar",
        },
        toasts: {
          success: "Atalho adicionado com sucesso!",
          deleted: "Atalho removido com sucesso!",
        },
        dialog: {
          title: "Mensagem Rápida",
          shortcode: "Atalho",
          message: "Resposta",
          save: "Salvar",
          cancel: "Cancelar",
          geral: "Permitir editar",
          add: "Adicionar resposta rápida",
          edit: "Editar",
          visao: "Permitir visão",
        },
        table: {
          shortcode: "Atalho",
          message: "Mensagem",
          actions: "Ações",
          mediaName: "Nome do Arquivo",
          status: "Status",
        },
      },
      messageVariablesPicker: {
        label: "Variavéis disponíveis",
        sublabel: "Use os comandos 'Primeiro nome', 'Nome', 'Saudação', 'Protocolo' e 'Hora' para personalização automática.",
        vars: {
          contactFirstName: "Primeiro Nome",
          contactName: "Nome",
          greeting: "Saudação",
          protocolNumber: "Protocolo",
          date: "Data",
          hour: "Hora",
        },
      },
      contactLists: {
        title: "Listas de Contatos",
        table: {
          name: "Nome",
          contacts: "Contatos",
          actions: "Ações",
        },
        buttons: {
          add: "Nova Lista",
        },
        dialog: {
          name: "Nome",
          company: "Empresa",
          okEdit: "Editar",
          okAdd: "Adicionar",
          add: "Adicionar",
          edit: "Editar",
          cancel: "Cancelar",
        },
        confirmationModal: {
          deleteTitle: "Excluir",
          deleteMessage: "Esta ação não pode ser revertida.",
        },
        toasts: {
          deleted: "Registro excluído",
        },
      },
      contactListItems: {
        title: "Contatos",
        searchPlaceholder: "Pesquisa",
        buttons: {
          add: "Novo",
          lists: "Listas",
          import: "Importar",
        },
        dialog: {
          name: "Nome",
          number: "Número",
          whatsapp: "Whatsapp",
          email: "E-mail",
          okEdit: "Editar",
          okAdd: "Adicionar",
          add: "Adicionar",
          edit: "Editar",
          cancel: "Cancelar",
        },
        table: {
          name: "Nome",
          number: "Número",
          whatsapp: "Whatsapp",
          email: "E-mail",
          actions: "Ações",
        },
        confirmationModal: {
          deleteTitle: "Excluir",
          deleteMessage: "Esta ação não pode ser revertida.",
          importMessage: "Deseja importar os contatos desta planilha? ",
          importTitlte: "Importar",
        },
        toasts: {
          deleted: "Registro excluído",
        },
      },
      campaigns: {
        title: "Campanhas",
        searchPlaceholder: "Pesquisa",
        buttons: {
          add: "Nova Campanha",
          contactLists: "Listas de Contatos",
        },
        table: {
          name: "Nome",
          whatsapp: "Conexão",
          contactList: "Lista de Contatos",
          status: "Status",
          scheduledAt: "Agendamento",
          completedAt: "Concluída",
          confirmation: "Confirmação",
          actions: "Ações",
        },
        dialog: {
          new: "Nova Campanha",
          update: "Editar Campanha",
          readonly: "Apenas Visualização",
          form: {
            name: "Nome",
            message1: "Mensagem 1",
            message2: "Mensagem 2",
            message3: "Mensagem 3",
            message4: "Mensagem 4",
            message5: "Mensagem 5",
            confirmationMessage1: "Mensagem de Confirmação 1",
            confirmationMessage2: "Mensagem de Confirmação 2",
            confirmationMessage3: "Mensagem de Confirmação 3",
            confirmationMessage4: "Mensagem de Confirmação 4",
            confirmationMessage5: "Mensagem de Confirmação 5",
            messagePlaceholder: "Conteúdo da mensagem",
            whatsapp: "Conexão",
            status: "Status",
            scheduledAt: "Agendamento",
            confirmation: "Confirmação",
            contactList: "Lista de Contato",
            tagList: "Lista de Etiquetas",
            fileList: "Lista de Arquivos",
            text_footer: "Utilize variáveis como {nome}, {numero}, {email} ou defina variáveis personalziadas."
          },
          buttons: {
            add: "Adicionar",
            edit: "Atualizar",
            okadd: "Ok",
            cancel: "Cancelar Disparos",
            restart: "Reiniciar Disparos",
            close: "Fechar",
            attach: "Anexar Arquivo",
          },
        },
        confirmationModal: {
          deleteTitle: "Excluir",
          deleteMessage: "Esta ação não pode ser revertida.",
        },
        toasts: {
          success: "Operação realizada com sucesso",
          cancel: "Campanha cancelada",
          restart: "Campanha reiniciada",
          deleted: "Registro excluído",
        },
      },
      announcements: {
        active: 'Ativo',
        inactive: 'Inativo',
        title: "Informativos",
        searchPlaceholder: "Pesquisa",
        buttons: {
          add: "Novo Informativo",
          contactLists: "Listas de Informativos",
        },
        table: {
          priority: "Prioridade",
          title: "Title",
          text: "Texto",
          mediaName: "Arquivo",
          status: "Status",
          actions: "Ações",
        },
        dialog: {
          edit: "Edição de Informativo",
          add: "Novo Informativo",
          update: "Editar Informativo",
          readonly: "Apenas Visualização",
          form: {
            priority: "Prioridade",
            title: "Title",
            text: "Texto",
            mediaPath: "Arquivo",
            status: "Status",
            input_status: {
              Active: "Ativo",
              Inactive: "Inativo",
            },
            input_priority: {
              High: "Altas",
              Medium: "Médias",
              Low: "Baixas",
            },
          },
          buttons: {
            add: "Adicionar",
            edit: "Atualizar",
            okadd: "Ok",
            cancel: "Cancelar",
            close: "Fechar",
            attach: "Anexar Arquivo",
          },
        },
        confirmationModal: {
          deleteTitle: "Excluir",
          deleteMessage: "Esta ação não pode ser revertida.",
        },
        toasts: {
          success: "Operação realizada com sucesso",
          deleted: "Registro excluído",
        },
      },
      campaignsConfig: {
        title: "Configurações de Campanhas",
        form: {
          intervals: "Intervalos",
          randomInterval: "Intervalo Randômico de Disparo",
          longerIntervalAfter: "Intervalo Maior Após",
          greaterInterval: "Intervalo de Disparo Maior",
          addVariable: "Adicionar Variável",
          saveSettings: "Salvar Configurações",
          shortcut: "Atalho",
          content: "Conteúdo",
          noInterval: "Sem Intervalo",
          close: "Fechar",
          add: "Adicionar", 
        },
        confirmationModal: {
          deleteTitle: "Excluir Variável",
          deleteMessage: "Esta ação não pode ser revertida. Tem certeza de que deseja excluir esta variável?",
        },
        menuItems: {
          second: "{{value}} segundos",
          undefined: "Não definido",
        },
        table: {
          shortcut: "Atalho",
          content: "Conteúdo",
          actions: "Ações",
        },
      },
      queues: {
        title: "Setores & Chatbot",
        table: {
          id: "ID",
          name: "Nome",
          color: "Cor",
          greeting: "Mensagem de saudação",
          actions: "Ações",
          orderQueue: "Ordenação do Setor (bot)",
        },
        buttons: {
          add: "Adicionar Setor",
        },
        confirmationModal: {
          deleteTitle: "Excluir",
          deleteMessage:
            "Você tem certeza? Essa ação não pode ser revertida! Os atendimentos desse setor continuarão existindo, mas não terão mais nenhum setor atribuído.",
        },
      },
      queueSelect: {
        inputLabel: "Setores",
      },
      users: {
        title: "Usuários",
        table: {
          id: "ID",
          name: "Nome",
          email: "Email",
          profile: "Perfil",
          actions: "Ações",
        },
        buttons: {
          add: "Adicionar usuário",
        },
        toasts: {
          deleted: "Usuário excluído com sucesso.",
        },
        confirmationModal: {
          deleteTitle: "Excluir",
          deleteMessage:
            "Todos os dados do usuário serão perdidos. Os atendimento abertos deste usuário serão movidos para o setor.",
        },
      },
      helps: {
        title: "Central de Ajuda",
      },
      schedules: {
        title: "Agendamentos",
        confirmationModal: {
          deleteTitle: "Você tem certeza que quer excluir este Agendamento?",
          deleteMessage: "Esta ação não pode ser revertida.",
        },
        table: {
          contact: "Contato",
          body: "Mensagem",
          sendAt: "Data de Agendamento",
          sentAt: "Data de Envio",
          status: "Status",
          actions: "Ações",
        },
        buttons: {
          add: "Novo Agendamento",
        },
        toasts: {
          deleted: "Agendamento excluído com sucesso.",
        },
      },
      tags: {
        title: "Etiquetas",
        confirmationModal: {
          deleteTitle: "Você tem certeza que quer excluir esta etiqueta?",
          deleteMessage: "Esta ação não pode ser revertida.",
          deleteAllMessage: "Tem certeza que deseja deletar todas as etiquetas?",
          deleteAllTitle: "Deletar Todos",
        },
        table: {
          name: "Nome",
          color: "Cor",
          tickets: "Registros Tagdos",
          actions: "Ações",
        },
        buttons: {
          add: "Nova Tag",
          deleteAll: "Deletar Todas",
        },
        toasts: {
          deletedAll: "Todas etiquetas excluídas com sucesso!",
          deleted: "Tag excluído com sucesso.",
        },
      },
      settings: {
        success: "Configurações salvas com sucesso.",
        title: "Configurações",
        tab:{
          title:{
            options: "Opções",
            companies: "Empresas",
            plans: "Planos",
          },  
          options: {
            labels: {
              userRating: "Avaliações",
              scheduleType: "Gerenciamento de Expediente",
              callType: "Aceitar Chamada",
              chatbotType: "Tipo Chatbot",
              groupType: "Ignorar Mensagens de Grupos",
              sendGreetingAccepted: "Saudação ao aceitar o ticket",
              sendMsgTransfTicket: "Mensagem de transferência de Fila/Agente",
              sendGreetingMessageOneQueues: "Saudação quando houver somente 1 fila",
              updating: "Atualizando...",
              disabled: "Desabilitado",
              enabled: "Habilitado",
              queue: "Fila",
              company: "Empresa",
              text: "Texto",
              noAccept: "Não Aceitar",
              accept: "Aceitar",
            },
            toasts: {
              success: "Operação atualizada com sucesso.",
            },
          },
        },
        settings: {
          userCreation: {
            name: "Criação de usuário",
            options: {
              enabled: "Ativado",
              disabled: "Desativado",
            },
          },
        },
      },
      messagesList: {
        header: {
          assignedTo: "Atribuído à:",
          buttons: {
            return: "Retornar",
            resolve: "Finalizar",
            reopen: "Reabrir",
            accept: "Aceitar",
          },
        },
      },
      // ate aqui o segundo
      messagesInput: {
        placeholderOpen: "Digite uma mensagem",
        placeholderClosed:
          "Reabra ou aceite esse ticket para enviar uma mensagem.",
        signMessage: "Assinar",
        pendding: {
          delete: "Excluir",
          transfer: "Transferir",
          attend: "Atender",
          confirmationTitle: "Tem certeza que deseja excluir o ticket #{{ticketId}}?",
          confirmationMessage: "Atenção! Todas as mensagens relacionadas ao ticket serão perdidas."
        }
      },
      contactDrawer: {
        header: "Dados do contato",
        buttons: {
          edit: "Editar contato",
        },
        extraInfo: "Outras informações",
      },
      fileModal: {
        title: {
          add: "Adicionar lista de arquivos",
          edit: "Editar lista de arquivos",
        },
        buttons: {
          okAdd: "Salvar",
          okEdit: "Editar",
          cancel: "Cancelar",
          fileOptions: "Adicionar arquivo",
        },
        form: {
          name: "Nome da lista de arquivos",
          message: "Detalhes da lista",
          fileOptions: "Lista de arquivos",
          extraName: "Mensagem para enviar com arquivo",
          extraValue: "Valor da opção",
        },
        success: "Lista de arquivos salva com sucesso!",
      },
      ticketOptionsMenu: {
        schedule: "Agendamento",
        delete: "Excluir",
        transfer: "Transferir atendimento",
        registerAppointment: "Observações do Contato",
        appointmentsModal: {
          title: "Observações do Contato",
          textarea: "Observação",
          placeholder: "Insira aqui a informação que deseja registrar",
        },
        confirmationModal: {
          title: "Deletar o ticket",
          titleFrom: "do contato ",
          message:
            "Atenção! Todas as mensagens relacionadas ao ticket serão perdidas.",
        },
        buttons: {
          delete: "Excluir",
          cancel: "Cancelar",
        },
      },
      confirmationModal: {
        buttons: {
          confirm: "Ok",
          cancel: "Cancelar",
        },
      },
      messageOptionsMenu: {
        delete: "Excluir",
        reply: "Responder",
        confirmationModal: {
          title: "Apagar mensagem?",
          message: "Esta ação não pode ser revertida.",
        },
      },
      backendErrors: {
        ERR_NO_OTHER_WHATSAPP: "Deve haver pelo menos um WhatsApp padrão.",
        ERR_NO_DEF_WAPP_FOUND:
          "Nenhum WhatsApp padrão encontrado. Verifique a página de Canais.",
        ERR_WAPP_NOT_INITIALIZED:
          "Esta sessão do WhatsApp não foi inicializada. Verifique a página de Canais.",
        ERR_WAPP_CHECK_CONTACT:
          "Não foi possível verificar o contato do WhatsApp. Verifique a página de Canais",
        ERR_WAPP_INVALID_CONTACT: "Este não é um número de Whatsapp válido.",
        ERR_WAPP_DOWNLOAD_MEDIA:
          "Não foi possível baixar mídia do WhatsApp. Verifique a página de Canais.",
        ERR_INVALID_CREDENTIALS:
          "Credenciais inválidas. Verifique seu usuário e senha e tente novamente.",
        ERR_SENDING_WAPP_MSG:
          "Erro ao enviar mensagem do WhatsApp. Verifique a página de Canais.",
        ERR_DELETE_WAPP_MSG: "Não foi possível excluir a mensagem do WhatsApp.",
        ERR_OTHER_OPEN_TICKET: "Já existe um tíquete aberto para este contato.",
        ERR_SESSION_EXPIRED: "Sessão expirada. Por favor entre.",
        ERR_USER_CREATION_DISABLED:
          "A criação do usuário foi desabilitada pelo administrador.",
        ERR_NO_PERMISSION: "Você não tem permissão para acessar este recurso.",
        ERR_DUPLICATED_CONTACT: "Já existe um contato com este número.",
        ERR_NO_SETTING_FOUND: "Nenhuma configuração encontrada com este ID.",
        ERR_NO_CONTACT_FOUND: "Nenhum contato encontrado com este ID.",
        ERR_NO_TICKET_FOUND: "Nenhum tíquete encontrado com este ID.",
        ERR_NO_USER_FOUND: "Nenhum usuário encontrado com este ID.",
        ERR_NO_WAPP_FOUND: "Nenhum WhatsApp encontrado com este ID.",
        ERR_CREATING_MESSAGE: "Erro ao criar mensagem no banco de dados.",
        ERR_CREATING_TICKET: "Erro ao criar tíquete no banco de dados.",
        ERR_FETCH_WAPP_MSG:
          "Erro ao buscar a mensagem no WhtasApp, talvez ela seja muito antiga.",
        ERR_QUEUE_COLOR_ALREADY_EXISTS:
          "Esta cor já está em uso, escolha outra.",
        ERR_WAPP_GREETING_REQUIRED:
          "A mensagem de saudação é obrigatório quando há mais de um setor.",
      },
      dashboard: {
        title: "Suas métricas",
        cards: {
          in_conversation: "Em Conversa",
          waiting: "Aguardando",
          finished: "Finalizados",
          new_contacts: "Novos Contatos",
          average_conversation_time: "T.M. de Conversa",
          average_waiting_time: "T.M. de Espera",
        },
        filter: {
          title: "Tipo de Filtro",
          type: {
            date: "Filtro por Data",
            period: "Filtro por Período",
          },
          date: {
            from: "Data Inicial",
            to: "Data Final",
          },
          period: {
            none_selected: "Nenhum selecionado",
            last_3_days: "Últimos 3 dias",
            last_7_days: "Últimos 7 dias",
            last_15_days: "Últimos 15 dias",
            last_30_days: "Últimos 30 dias",
            last_60_days: "Últimos 60 dias",
            last_90_days: "Últimos 90 dias",
          },
          placeholder: "Selecione o período desejado",
        },
        buttons: {
          filter: "FILTRAR",
        },
        table: {
          name: "Nome",
          evaluations: "Avaliações",
          service_time: "T.M. de atendimento",
          last_update: "Última atualização",
          status: "Status (Atual)",
        },
        bar_chart: {
          title: "Total de Conversas por Usuários",
          y_axis_title: "Gráfico de Conversas",
          data: {
            start: "Início",
            end: "Fim",
          },
          buttons: {
            filter: "Filtrar", 
          },
        },
        bar_chart_total: {
          title: "Total",
          y_axis_title: "Gráfico de Conversas",
          data: {
            start: "Início",
            end: "Fim",
          },
          buttons: {
            filter: "Filtrar",
          },
        }
      },
      kanban:{
        buttons:{
          create_step: "Criar Etapa",
          open_ticket: "Ver Ticket"
        },
        step_open:"Em aberto",
      },
      calendar: {
        date: "Data",
        time: "Hora",
        event: "Evento",
        allDay: "Dia Todo",
        week: "Semana",
        work_week: "Agendamentos",
        day: "Dia",
        month: "Mês",
        previous: "Anterior",
        next: "Próximo",
        yesterday: "Ontem",
        tomorrow: "Amanhã",
        today: "Hoje",
        agenda: "Agenda",
        noEventsInRange: "Não há agendamentos no período.",
        showMore: "+{{count}} mais",
        weekdays: {
          sunday: "Domingo",
          monday: "Segunda-feira",
          tuesday: "Terça-feira",
          wednesday: "Quarta-feira",
          thursday: "Quinta-feira",
          friday: "Sexta-feira",
          saturday: "Sábado"
        }
      },
      companiesManager: {
        table: {
          name: "Nome",
          email: "E-mail",
          phone: "Telefone",
          plan: "Plano",
          campaigns: "Campanhas",
          status: "Status",
          createdAt: "Criada Em",
          dueDate: "Vencimento",
          recurrence: "Recorrência",
          edit: "Editar",
          delete: "Excluir"
        },
        form: {
          titleAdd: "Adicionar Empresa",
          titleEdit: "Editar Empresa",
          name: "Nome",
          email: "E-mail",
          phone: "Telefone",
          plan: "Plano",
          status: "Status",
          campaigns: "Campanhas",
          dueDate: "Data de Vencimento",
          cancel: "Cancelar",
          save: "Salvar Alterações",
          user: "Usuário",
        },
        status: {
          active: "Ativo",
          inactive: "Inativo"
        },
        campaignsStatus: {
          enabled: "Habilitado",
          disabled: "Desabilitado"
        },
        confirmDeletion: {
          title: "Exclusão de Registro",
          message: "Deseja realmente excluir esse registro?"
        },
        toast: {
          errorCompanies: "Não foi possível carregar a lista de registros",
          errorLoadCompanies: "Erro ao buscar empresas",
          successUpdate: "Operação realizada com sucesso!",
          errorUpdate: "Erro ao salvar os dados.",
          successDelete: "Empresa excluída com sucesso!",
          errorDelete: "Erro ao excluir a empresa.",
          errorUsers: "Erro ao carregar usuários da empresa.",
          successLoad: "Operação atualizada com sucesso!",
          errorNotCompanie: "Empresa ainda não foi criada. Salve a empresa primeiro."
        },
        searchPlaceholder: "Buscar",
        addButton: "+ Adicionar Empresa",
        updating: "Atualizando...",
      },
      plansManager: {
        table: {
          name: "Nome",
          users: "Usuários",
          connections: "Conexões",
          queues: "Filas",
          value: "Valor",
          useCampaigns: "Campanhas",
          actions: "Ações"
        },
        form: {
          addTitle: "Adicionar Plano",
          editTitle: "Editar Plano",
          name: "Nome",
          users: "Usuários",
          connections: "Conexões",
          queues: "Setores",
          value: "Valor",
          useCampaigns: "Campanhas",
          useSchedules: "Agendamentos",
          useInternalChat: "Chat Interno",
          useExternalApi: "API Externa",
          useKanban: "Kanban",
          useOpenAi: "Open AI",
          useIntegrations: "Integrações",
          enabled: "Habilitado",
          disabled: "Desabilitado",
          cancel: "Cancelar",
          save: "Salvar"
        },
        searchPlaceholder: "Buscar",
        addButton: "+ Adicionar Plano",
        confirmDeletion: {
          title: "Exclusão de Plano",
          message: "Deseja realmente excluir esse plano?"
        },
        toast: {
          errorLoadPlans: "Não foi possível carregar a lista de registros",
          errorSearchPlans: "Erro ao buscar planos",
          successDelete: "Plano excluído com sucesso!",
          errorDelete: "Erro ao excluir o plano.",
          successAdd: "Plano adicionado com sucesso!",
          successUpdate: "Plano atualizado com sucesso!",
          errorSave: "Erro ao salvar o plano. Verifique os campos e tente novamente."
        },
        updating: "Atualizando..."
      }
    },
  }
};

export { messages };
