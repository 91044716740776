import React, { useContext, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import SearchIcon from "@material-ui/icons/Search";
import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@material-ui/icons/Add"; 
import InputBase from "@material-ui/core/InputBase";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Badge from "@material-ui/core/Badge";
import MoveToInboxIcon from "@material-ui/icons/MoveToInbox";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import Divider from "@material-ui/core/Divider";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";

import NewTicketModal from "../NewTicketModal";
import TicketsList from "../TicketsListCustom";
import TabPanel from "../TabPanel";

import { i18n } from "../../translate/i18n";
import { AuthContext } from "../../context/Auth/AuthContext";
import { Can } from "../Can";
import TicketsQueueSelect from "../TicketsQueueSelect";
import { Button } from "@material-ui/core";
import { TagsFilter } from "../TagsFilter";
import { UsersFilter } from "../UsersFilter";
import CustomersWaiting from "../CustomersWaiting";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

const useStyles = makeStyles(theme => ({
  ticketsWrapper :{
    backgroundColor: `${theme.palette.type === 'dark' ? theme.palette.cardnormal : "#fff"} !important`
  },
  tabsContainer:{
    width: "fit-content",
    // maxWidth: "310px",
    margin: "0 auto",
  },
	tabsWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: theme.palette.type === 'dark' ? theme.palette.inputBackgroundColor : theme.palette.tabHeaderBackground,
    borderRadius: "24px",
    padding: "4px",
    width: "fit-content",
    margin: "14px auto 0",
  },

  tabRoot: {
    minWidth: "150px",
    padding: "4px 16px",
    borderRadius: "24px",
    textTransform: "none",
    fontWeight: 700,
    "&.Mui-selected": {
      backgroundColor: `${theme.palette.type === 'dark' ? theme.palette.cardnormal : "#fff"} !important`, // Fundo branco quando selecionado
      color: `${theme.palette.type === 'dark' ? "#fff": theme.palette.text.primary} !important`, // Texto preto quando selecionado
    },
    "&.MuiTab-root": {
      backgroundColor: theme.palette.type === 'dark' ? theme.palette.inputBackgroundColor : theme.palette.tabHeaderBackground,
      color: "#7d7d7d",
    },
  },

	tabsHeader: {
		flex: "none",
		backgroundColor: theme.palette.tabHeaderBackground,
	},

	tabsInternal: {
		flex: "none",
		backgroundColor: theme.palette.tabHeaderBackground
	},

	settingsIcon: {
		alignSelf: "center",
		marginLeft: "auto",
		padding: 8,
	},

	tab: {
		minWidth: 120,
		width: 120,
    backgroundColor: theme.palette.cardnormal,
	},

	internalTab: {
		minWidth: 120,
		width: 120,
		padding: 5
	},

	ticketOptionsBox: {
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
		background: theme.palette.cardnormal,
		padding: theme.spacing(1),
    overflowX: "auto", // Adicione esta linha para permitir scroll horizontal
    whiteSpace: "nowrap", // Impede que o conteúdo seja quebrado em múltiplas linhas
    gap: "8px",
    "&::-webkit-scrollbar": {
      height: "4px", // Altura do scrollbar
    },
    "&::-webkit-scrollbar-track": {
      background: theme.palette.cardnormal,
      borderRadius: "10px",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#6EB131", // Cor do thumb do scrollbar
      borderRadius: "10px",
    },
	},

  buttonsContainer: {
    display: "flex",
    alignItems: "center",
    whiteSpace: "nowrap", // Impede a quebra de linha
    gap: "8px",
  },
	
  buttonGroup: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "16px",
  },

  button: {
    padding: "8px 16px",
    borderRadius: "16px",
    textTransform: "none",
    backgroundColor: theme.palette.type === 'dark' ? theme.palette.inputBackgroundColor : theme.palette.tabHeaderBackground,
    color: theme.palette.text.primary,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    flexShrink: 0,
    "&.selected": {
      backgroundColor: "#6EB131", //theme.palette.primary.main, // Cor de fundo verde para o botão selecionado
      color: "#fff", // Texto branco quando selecionado
    },
  },

  ticketSearchLine: {
		padding: theme.spacing(1),
	},

	searchWrapper: {
    display: "flex",
    alignItems: "center",
    backgroundColor: theme.palette.type === 'dark' ? theme.palette.inputBackgroundColor : theme.palette.tabHeaderBackground,
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: "24px",
    padding: "4px 16px",
    marginTop: "16px",
    margin: "0 auto",
    width: "100%",
  },

  searchIcon: {
    color: "grey",
    marginRight: "8px",
  },

  searchInput: {
    border: "none",
    background: "none",
    outline: "none",
    fontSize: "16px",
    width: "200px",
  },

	insiderTabPanel: {
		height: '100%',
		marginTop: "-72px",
		paddingTop: "72px"
	},

	insiderDoubleTabPanel: {
		display:"flex",
		flexDirection: "column",
		marginTop: "-72px",
		paddingTop: "72px",
		height: "100%"
	},

	labelContainer: {
		width: "auto",
		padding: 0
	},
	iconLabelWrapper: {
		flexDirection: "row",
		'& > *:first-child': {
			marginBottom: '3px !important',
			marginRight: 16
		}
	},
	insiderTabLabel: {
		[theme.breakpoints.down(1600)]: {
			display:'none'
		}
	},
	smallFormControl: {
		'& .MuiOutlinedInput-input': {
			padding: "12px 10px",
		},
		'& .MuiInputLabel-outlined': {
			marginTop: "-6px"
		}
	},
  backButtonWrapper: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(2),
    cursor: "pointer",
  },
  backIcon: {
    marginRight: theme.spacing(1),
    color: "grey",
  },
  backText: {
    fontWeight: 700,
    fontSize: "1rem",
    color: theme.palette.text.primary,
  },
}));

const TicketsManagerTabs = () => {
  const classes = useStyles();
  const history = useHistory();

  const [searchParam, setSearchParam] = useState("");
  const [tab, setTab] = useState("open");
  const [tabOpen, setTabOpen] = useState("open");
  const [newTicketModalOpen, setNewTicketModalOpen] = useState(false);
  const [showAllTickets, setShowAllTickets] = useState(false);
  const searchInputRef = useRef();
  const { user } = useContext(AuthContext);
  const { profile } = user;

  const [openCount, setOpenCount] = useState(0);
  const [pendingCount, setPendingCount] = useState(0);
  const [closedCount, setClosedCount] = useState(0)

  const userQueueIds = user.queues.map((q) => q.id);
  const [selectedQueueIds, setSelectedQueueIds] = useState(userQueueIds || []);
  const [selectedTags, setSelectedTags] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedTab, setSelectedTab] = useState("todos");

  useEffect(() => {
    if (user.profile.toUpperCase() === "ADMIN") {
      setShowAllTickets(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (tab === "search") {
      searchInputRef.current.focus();
    }
  }, [tab]);
  

  let searchTimeout;

  const handleSearch = (e) => {
    const searchedTerm = e.target.value.toLowerCase();

    clearTimeout(searchTimeout);

    if (searchedTerm === "") {
      setSearchParam(searchedTerm);
      setTab("open");
      return;
    }

    searchTimeout = setTimeout(() => {
      setSearchParam(searchedTerm);
    }, 500);
  };

  // Função para alterar o estado da tab selecionada
  const handleTabChange = (tab) => {
    setSelectedTab(tab);
  };

  const handleChangeTab = (e, newValue) => {
    setTabOpen(newValue);
  };

  const handleChangeTabOpen = (e, newValue) => {
    setTabOpen(newValue);
  };

  const applyPanelStyle = (status) => {
    if (tabOpen !== status) {
      return { width: 0, height: 0 };
    }
  };

  const handleCloseOrOpenTicket = (ticket) => {
    setNewTicketModalOpen(false);
    if (ticket !== undefined && ticket.uuid !== undefined) {
      history.push(`/tickets/${ticket.uuid}`);
    }
  };

  const handleSelectedTags = (selecteds) => {
    const tags = selecteds.map((t) => t.id);
    setSelectedTags(tags);
  };

  const handleSelectedUsers = (selecteds) => {
    const users = selecteds.map((t) => t.id);
    setSelectedUsers(users);
  };

  const handleQueueSelection = (id) => {
    if (showAllTickets) {
      setShowAllTickets((prevState) => !prevState)
    }     
    
    // Verifica se o ID já está na lista de IDs selecionados
    if (selectedQueueIds.includes(id)) {
      // Se estiver, remove o ID (deseleciona)
      setSelectedQueueIds((prev) => prev.filter((queueId) => queueId !== id));
    } else {
      // Se não estiver, adiciona o ID (seleciona)
      setSelectedQueueIds((prev) => [...prev, id]);
    }
  };

  const handleSelectAllQueues = () => {
    if (selectedQueueIds.length === user.queues.length) {
      // Se todos os queues estiverem selecionados, desmarque todos
      setSelectedQueueIds([]);
    } else {
      // Caso contrário, selecione todos os queues
      setSelectedQueueIds(userQueueIds);
    }
  };
  

  return (
    <Paper elevation={0} variant="outlined" className={classes.ticketsWrapper}>
      <NewTicketModal
        modalOpen={newTicketModalOpen}
        onClose={(ticket) => {
          
          handleCloseOrOpenTicket(ticket);
        }}
      />
      {tabOpen !== 'pending' ? (
        <>
      <div className={classes.tabsContainer}>
        <Paper elevation={0} className={classes.tabsWrapper}>
        <Tabs
          value={tabOpen}
          onChange={handleChangeTab}
          indicatorColor="primary"
          textColor="primary"
          // variant="fullWidth"
          TabIndicatorProps={{ style: { display: "none" } }}
        >
          <Tab
            value={"open"}
            label={i18n.t("ticketsList.assignedHeader") + ` (${openCount})`}
            className={classes.tabRoot}
          />
          <Tab
            value={"closed"}
            label={i18n.t("ticketsList.finished") + ` (${closedCount})` }
            className={classes.tabRoot}
          />
        </Tabs>
      </Paper>
      {/* Novo botão de pending */}
        <CustomersWaiting 
          pendingCount={pendingCount} 
          onAttendClick={() => setTabOpen('pending')} // Altera a aba para "pending"
        />
        <div className={classes.searchWrapper}>
          <SearchIcon className={classes.searchIcon} />
          <InputBase
            className={classes.searchInput}
            inputRef={searchInputRef}
            placeholder={i18n.t("tickets.search.placeholder")}
            type="search"
            onChange={handleSearch}
          />
        </div>
      </div>
      {/* setores de botões */}
      <Paper square elevation={0} className={classes.ticketOptionsBox}>
        <div className={classes.buttonsContainer}>

          <IconButton
            color="primary"
            onClick={() => setNewTicketModalOpen(true)}
          >
            <AddIcon />
          </IconButton>

          <Can
            role={user.profile}
            perform="tickets-manager:showall"
            yes={() => (
              <Button
                className={`${classes.button} ${selectedQueueIds.length === user.queues.length ? "selected" : ""}`}
                onClick={handleSelectAllQueues} // Usa o novo manipulador de eventos
              >
                {i18n.t("tickets.buttons.showAll")}
              </Button>
            )}
          />
          <Divider orientation="vertical" flexItem className={classes.divider} />
          {user?.queues.length > 0 &&
            user?.queues.map(queue => (
              <Button
              key={queue.id}
                className={`${classes.button} ${selectedQueueIds.includes(queue.id) || showAllTickets ? "selected" : ""}`}
                // variant="outlined"
                // color={showAllTickets ? "secondary" : "primary"}
                onClick={() => handleQueueSelection(queue.id)}
              >
                {queue.name}
              </Button>
              ))}
        </div>
      </Paper>
      </>) : (
      // Botão de voltar para a lista de tickets
      <div className={classes.backButtonWrapper} onClick={() => setTabOpen("open")}>
      <ArrowBackIcon className={classes.backIcon} />
      <span className={classes.backText}>{i18n.t("ticketsList.assignedHeader") + ` (${openCount})`}</span>
    </div>)}
      <TabPanel value={tab} name="open" className={classes.ticketsWrapper}>
        {/* <Tabs
          value={tabOpen}
          onChange={handleChangeTabOpen}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
        >
          <Tab
            label={
              <Badge
                className={classes.badge}
                badgeContent={openCount}
                color="primary"
              >
                {i18n.t("ticketsList.assignedHeader")}
              </Badge>
            }
            value={"open"}
          />
          <Tab
            label={
              <Badge
                className={classes.badge}
                badgeContent={pendingCount}
                color="secondary"
              >
                {i18n.t("ticketsList.pendingHeader")}
              </Badge>
            }
            value={"pending"}
          />
        </Tabs> */}
        {/* Mantenha todos os TicketsList montados, mas esconda-os com CSS */}
    <Paper className={classes.ticketsWrapper}>
      <div style={{ display: tabOpen === "open" ? "block" : "none" }}>
        <TicketsList
          status="open"
          showAll={showAllTickets}
          selectedQueueIds={selectedQueueIds}
          updateCount={(val) => setOpenCount(val)}
        />
      </div>

    <div style={{ display: tabOpen === "pending" ? "block" : "none" }}>
      <TicketsList
        status="pending"
        selectedQueueIds={selectedQueueIds}
        updateCount={(val) => setPendingCount(val)}
      />
    </div>

    <div style={{ display: tabOpen === "closed" ? "block" : "none" }}>
      <TicketsList
        status="closed"
        showAll={true}
        selectedQueueIds={selectedQueueIds}
        updateCount={(val) => setClosedCount(val)}
      />
    </div>
  </Paper>
      </TabPanel>
      <TabPanel value={tab} name="search" className={classes.ticketsWrapper}>
        <TagsFilter onFiltered={handleSelectedTags} />
        {profile === "admin" && (
          <UsersFilter onFiltered={handleSelectedUsers} />
        )}
        <TicketsList
          searchParam={searchParam}
          showAll={true}
          tags={selectedTags}
          users={selectedUsers}
          selectedQueueIds={selectedQueueIds}
        />
      </TabPanel>

    </Paper>
  );
};

export default TicketsManagerTabs;
